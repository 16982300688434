<template>
  <div class="backend-setting">
    <div class="backend-setting-wrap">
      <!-- 保留空間 -->
      <!-- <div class="title">保留空間</div> -->
      <div class="item">
        <div class="name">保留空間</div> <!-- 1-5000GB (下拉式選單） -->
        <el-select
          v-model="keepSpace"
          :popper-append-to-body="false"
          popper-class="select-popper"
          placeholder="請選擇"
        >
          <el-option
            v-for="item in keepSpaceOption"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
            {{ item.label }}
          </el-option>
        </el-select>
      </div>

      <div class="title">車號資料庫打包頻率</div>
      <div class="item">
        <div class="name">訂閱</div> <!-- 1-10分鐘 (下拉式選單） -->
        <el-select
          v-model="subscriptionMin"
          :popper-append-to-body="false"
          popper-class="select-popper"
          placeholder="請選擇"
        >
          <el-option
            v-for="item in subscriptOption"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
            {{ item.label }}
          </el-option>
        </el-select>
      </div>
      <div class="item">
        <div class="name">任務</div> <!-- 1-10分鐘 (下拉式選單） -->
        <el-select
          v-model="missionMin"
          :popper-append-to-body="false"
          popper-class="select-popper"
          placeholder="請選擇"
        >
          <el-option
            v-for="item in subscriptOption"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
            {{ item.label }}
          </el-option>
        </el-select>
      </div>
      <div class="item">
        <div class="name">標記</div> <!-- 10-60分鐘 （下拉式選單）-->
        <el-select
          v-model="tagMin"
          :popper-append-to-body="false"
          popper-class="select-popper"
          placeholder="請選擇"
        >
          <el-option
            v-for="item in tagOption"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
            {{ item.label }}
          </el-option>
        </el-select>
      </div>
      <div class="title">人物資料庫</div>
      <div class="item">
        <div class="name">註冊圖張數限制</div> <!-- 5,10 張 (下拉式選單） -->
        <el-select
          v-model="frPhotoCnt"
          :popper-append-to-body="false"
          popper-class="select-popper"
          placeholder="請選擇"
        >
          <el-option
            v-for="item in photoCntOption"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
            {{ item.label }}
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="btn" @click="onSave">{{ $t('save') }}</div>
  </div>
</template>

<script>
import {
  apiCheckStatus, apiErrorMsg,
  apiGetServerList, apiPutServer,
  apiGetSettingLPR, apiPutSettingLPR
} from '@/api/index.js'
export default {
  name: 'BackendSetting',
  data() {
    return {
      // 保留空間 ---
      serverMap: null,
      keepSpace: 200,
      keepSpaceOption: [
        {value: 1, label: '1 GB'},
        {value: 10, label: '10 GB'},
        {value: 20, label: '20 GB'},
        {value: 50, label: '50 GB'},
        {value: 100, label: '100 GB'},
        {value: 200, label: '200 GB'},
        {value: 500, label: '500 GB'},
        {value: 1000, label: '1000 GB'},
        {value: 1500, label: '1500 GB'},
        {value: 3000, label: '3000 GB'},
        {value: 5000, label: '5000 GB'},
      ],
      // 車號資料庫
      subscriptionMin: 10,
      missionMin: 8,
      tagMin: 30,
      subscriptOption: [
        {value: 1, label: '1 分鐘'},
        {value: 2, label: '2 分鐘'},
        {value: 3, label: '3 分鐘'},
        {value: 4, label: '4 分鐘'},
        {value: 5, label: '5 分鐘'},
        {value: 6, label: '6 分鐘'},
        {value: 7, label: '7 分鐘'},
        {value: 8, label: '8 分鐘'},
        {value: 9, label: '9 分鐘'},
        {value: 10, label: '10 分鐘'},
      ],
      tagOption: [
        {value: 10, label: '10 分鐘'},
        {value: 20, label: '20 分鐘'},
        {value: 30, label: '30 分鐘'},
        {value: 40, label: '40 分鐘'},
        {value: 50, label: '50 分鐘'},
        {value: 60, label: '60 分鐘'},
      ],
      // 人物資料庫 ---
      frPhotoCnt: 5,
      photoCntOption: [
        {value: 5, label: '5 張'},
        {value: 10, label: '10 張'}
      ],

      // ui ---
      org: {
        // 保留空間
        keepSpace: 0,
        // 車號資料庫
        subscriptionMin: 10,
        missionMin: 8,
        tagMin: 30,
        // 人物資料庫
        frPhotoCnt: 5
      }
    }
  },
  mounted() {
    this.getServer()
    this.getSettingLPR()
  },
  computed: {
    isServerDiff() {
      const { keepSpace } = this.org
      const diff = keepSpace !== this.keepSpace
      return diff
    },
    isLprSettingDiff() {
      const { subscriptionMin, missionMin, tagMin } = this.org
      const subDiff = subscriptionMin !== this.subscriptionMin
      const missDiff = missionMin !== this.missionMin
      const tagDiff = tagMin !== this.tagMin

      return subDiff || missDiff || tagDiff
    },
    isFrSettingDiff() {
      // TODO
      return true
    }
  },
  methods: {
    // 保留空間 ---
    async getServer() {
      try {
        const res = await apiGetServerList()

        if (!apiCheckStatus(res)) throw res

        const serverListObj = JSON.parse(JSON.stringify(res.data))
        const site = Object.keys(serverListObj)[0] // site 只會有一個
        let serverMap = serverListObj[site]
        // console.log(`[getServer] serverMap:`, serverMap)
        let serverKeys = Object.keys(serverMap)
        const keepSpace = Math.max(
          ...serverKeys.map((_k) => serverMap[_k].keepSpace)
        )

        this.keepSpace = keepSpace >= 1 ? keepSpace : 1
        this.serverMap = serverMap

        // 備份資料
        this.org = {
          ...this.org,
          ...{ keepSpace }
        }
      } catch (err) {
        this.$notify({
          type: 'error',
          title: '取得資料失敗',
          message: err ? this.$t(apiErrorMsg(err)) : this.$t('api_error')
        })
      }
    },
    async saveServer() {
      try {
        const serverKeys = Object.keys(this.serverMap)
        let res = null
        for await (const server of serverKeys) {
          const param = {
            id: server,
            keepSpace: this.keepSpace
          }
          // console.log(`[saveServer] param:`, param)
          res = await apiPutServer(param)

          // console.log(`[saveServer] res:`, res)
          if (!apiCheckStatus(res)) break
        }
        if (!apiCheckStatus(res)) throw res

        this.$notify({
          type: 'success',
          title: '儲存保留空間',
          message: this.$t('success')
        })
      } catch (err) {
        this.$notify({
          type: 'error',
          title: '儲存保留空間',
          message: err ? this.$t(apiErrorMsg(err)) : this.$t('api_error')
        })
      } finally {
        this.getServer()
      }
    },
    // 車號資料庫打包頻率 ---
    async getSettingLPR() {
      let res = await apiGetSettingLPR()
      // console.log(`[getSettingLPR] res:`, res)
      if (res.status == 200) {
        //console.log(res)
        this.subscriptionMin = res['data'].subscriptionDatabasePackedInterval
        this.missionMin = res['data'].missionDatabasePackedInterval
        this.tagMin = res['data'].tagDatabasePackedInterval

        // 備份資料
        this.org = {
          ...this.org,
          ...{
            subscriptionMin: this.subscriptionMin,
            missionMin: this.missionMin,
            tagMin: this.tagMin
          }
        }
      }
    },
    async saveSettingLPR() {
      let data = {
        tagDatabasePackedInterval: this.tagMin,
        subscriptionDatabasePackedInterval: this.subscriptionMin,
        missionDatabasePackedInterval: this.missionMin
      }
      try {
        let res = await apiPutSettingLPR(data)
        if (res.status == 200) {
          // this.$message.success(`${res.status} Save ${res.statusText}！`)
          this.$notify({
            type: 'success',
            message: `${res.status} Save ${res.statusText}！`
          })
          //console.log(res)
          this.getSettingLPR()
        }
      } catch (err) {
        // this.$message.warning(`${err}`)
        this.$notify({
          type: 'warning',
          message: `${err}`
        })
      }
    },
    // 人物資料庫 ---
    async getSettingFR() {
      // TODO
    },
    async saveSettingFR() {
      // TODO
    },

    async onSave() {
      if (this.isServerDiff) {
        await this.saveServer()
      }

      if (this.isLprSettingDiff) {
        await this.saveSettingLPR()
      }

      if (this.isFrSettingDiff) {
        await this.saveSettingFR()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
.backend-setting {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  background: #282942;
  color: #ffffff;
  font-size: 1.5rem; // 24px;
  // line-height: 48px;
  // background-color: #00f;

  .backend-setting-wrap {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: px2rem(40) px2rem(2) 0 px2rem(40);
    width: 100%;
    overflow-y: scroll;
    // background-color: #f00;
  }
}

.title {
  line-height: 48px;
  // font-weight: normal;
  margin-bottom: 20px;
  color: $color_FFE99F;
}

.item {
  display: flex;
  margin-bottom: 24px;
  width: 100%;
}

.name {
  margin-right: 12px;
}

.btn {
  display: inline-block;
  background: #4A5C7880;
  border-radius: 0.5rem;
  font-size: 24px;
  line-height: 36px;
  padding: 6px 32px;
  cursor: pointer;
  margin-top: px2rem(32);
  margin-bottom: px2rem(24);
}

.btn:hover {
  background: #4A5C78;
}

::v-deep .el-input__inner {
  font-size: 1.25rem; // 20px;
  // font-weight: 300;
}

::v-deep .select-popper li {
 font-size: 1.25rem; // 20px;
//  font-weight: 300;
}
</style>